<template>
  <div class="grow elevator-container">
    <DialogOverlay :text="$t('yakuza.elevator.nothing_found')" :show="showError" @onOk="showError = false; cancel();"></DialogOverlay>
    <DialogOverlay :text="$t('yakuza.elevator.solved')" :show="showSuccess" hideOK></DialogOverlay>    

    <div :class="`elevator ${ shake ? 'vibrate-1' : ''}`">
      <v-img
        class="elevator-bg"      
        :src="`${casePublicImages}/elevator/bg.svg`"
      ></v-img>
      <div :class="`elevator-screen ${ fontSize }`" v-html="screenTxt"></div>

      <div class="elevator-numpad">
        <v-hover v-for="(v,k) in numpad" :key="k">
          <div 
            slot-scope="{ hover }"
            v-if="hover"
            class="elevator-button"
            @click="numpadClick(v)"
          >{{ v }}</div>
          <div 
            v-else
            class="elevator-button"
            @click="numpadClick(v)"
          >{{ v }}</div>                       
        </v-hover>
      </div>

      <div class="elevator-badge" @click="passBadge()">
      </div>

      <Sound :src="`${casePublicAudio}/elevator/bossa.mp3`" ref="bossaSound" />  
      <Sound :src="`${casePublicAudio}/elevator/ding.mp3`" ref="dingSound" />       
    </div>
  </div>
</template>
  
<script>
  import DialogOverlay from "@/components/DialogOverlay";
  import Sound from "@/components/yakuza/Sound";
  import currentGameMixin from "@/mixins/currentGameMixin";
  
  let ElevatorStatus = {
    IDLE: 0,
    COMPOSING: 1,
    ERROR_FLOOR: 2,
    RUNNING: 3,
    WAIT_CARD: 4,
    ERROR_CARD: 5,
  }

  export default {
    name: "Elevator",
    components: { DialogOverlay, Sound },
    mixins: [currentGameMixin],

    data: () => ({
      screen: '',
      floor: '',
      currentFloor: 0,
      status: ElevatorStatus.IDLE,
      maxFloorSize: 2,
      maxFloor: 54,
      shake: false,
      numpad: ["1","2","3","4","5","6","7","8","9","C","0","OK"],
      showError: false,
      showSuccess: false,
    }),

    methods: {
      numpadClick(v) {
        if(v === "C") {
          this.cancel();
          return;
        }

        if(this.status === ElevatorStatus.RUNNING) {
          return;
        }

        if(v === "OK") {
          if(this.floor.length > 0) {
            this.go();
          }
          return;
        }

        this.status = ElevatorStatus.COMPOSING;

        if(this.floor.length < this.maxFloorSize) {
          this.floor += v;
        }
      },
      passBadge() {
        if(this.status === ElevatorStatus.WAIT_CARD) {
          const a = this.$yakuzaStore.hack.authorizations.find((v) => v.name === this.$yakuzaStore.elevator.correctAuthorization);

          if(a.enabledPenthouse) {
            this.elevate();
          } else {
            this.status = ElevatorStatus.ERROR_CARD;
            this.floor = '';
          }
        }

      },
      success() {
        this.showSuccess = true;
        this.$yakuzaStore.elevator.solved = true;
        this.$yakuzaStore.enableEnvelope("enveloppeB");
        this.$yakuzaStore.enableEnvelope("enveloppeC");
      },
      cancel() {
        this.screen = this.$t("yakuza.elevator.welcome");
        this.status = ElevatorStatus.IDLE;
        this.floor = '';
        this.$refs.bossaSound.stop();
      },
      go() {
        if(Number(this.floor) > this.maxFloor || Number(this.floor) == 0) {
          this.status = ElevatorStatus.ERROR_FLOOR;
          this.floor = '';
        } else {
          if(Number(this.floor) === this.$yakuzaStore.elevator.correctFloor) {
            this.status = ElevatorStatus.WAIT_CARD;
          } else {
            this.elevate();
          }
        }
      },
      elevate() {
        this.$refs.bossaSound.play();
        this.status = ElevatorStatus.RUNNING;

        this.shake = true;
        setTimeout(() => {
          this.shake = false;
        }, 1000);

        this.$refs.dingSound.stop();

        this.currentFloor=0;        
        this.intervalFloor = setInterval(() => {
          this.currentFloor++;

          if(this.currentFloor === Number(this.floor)) {
            this.$refs.bossaSound.stop();
            this.$refs.dingSound.play();
            
            clearInterval(this.intervalFloor);

            if(Number(this.floor) === this.$yakuzaStore.elevator.correctFloor) {
              this.success();
            } else {
              setTimeout(() => {
                this.showError = true;
              }, 500);
            }
          }
        }, this.$yakuzaStore.elevator.speed);
      }
    },

    computed: {
      fontSize() {
        switch(this.status) {
          case 0:
          case 2:
          case 4:
            return 's16pt';
          case 1:
          case 3:
            return 's32pt';           
          case 5:
            return 's10pt';            
        }
        return '';
      },
      screenTxt() {
        switch(this.status) {
          case 0:
            return this.$t("yakuza.elevator.welcome");
          case 1:
            return this.floor;
          case 2:
            return this.$t("yakuza.elevator.floor_not_exists");
          case 3:
            return `<span class='pulse pulsate-bck'><img width='48' src='${this.casePublicImages}/elevator/fleche.svg' /></span>&nbsp;${this.currentFloor}`;
          case 4:
            return this.$t("yakuza.elevator.card_needed");
          case 5:
            return this.$t("yakuza.elevator.card_refused");
        }
        return '';
      }
    },

    mounted() {
      if(!this.$yakuzaStore.elevator.solved) {
        this.cancel();
      } else {
        this.success();
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/css/yakuza.scss';
  .elevator {
    position: relative;
  }

  .elevator-container {
    position: relative;
  }

  .elevator-bg {
    width: 300px;
    margin: auto;
  }

  ::v-deep .pulse {
    display: inline-block;
  }

  .elevator-screen {
    @include size(200px, 100px);
    position: absolute;
    top: 50px;
    left: calc(50% - 100px);
    background: rgb(10,10,10);
    
    border: 1px solid #fff;
    border-radius: 4px;
    color: #c20e1a;
    font-family: "Led board";
    font-size: 16pt;
    text-align: center;

    &.s16pt {
      font-size: 14pt;
      padding-top: 10px;
    }

    &.s10pt {
      font-size: 8pt;
      padding-top: 10px;
      font-weight: 600;
    }    

    &.s24pt {
      font-size: 24pt;      
    }
    &.s32pt {
      font-size: 32pt;
      padding-top: 15px;
    }    
  }

  .elevator-numpad {
    @include size(250px, 250px);
    position: absolute;
    top: 175px;
    left: calc(50% - 125px);
    text-align: center;

    .elevator-button {
      @include size(48px, 48px);
      display: inline-block;
      border: 2px solid #fff;
      border-radius: 8px;
      margin: 8px;
      color: #fff;
      font-family: "Arimo";
      padding-top: 8px;
      transition: border-color 0.2s;

      &:hover {
        border: 2px solid #0f0;
        cursor: pointer;
      }
    }
  }

  .elevator-badge {
    @include size(113px, 78px);
    position: absolute;
    top: 445px;
    left: calc(50% - 58px);
    text-align: center;
    border: 2px solid #fff;
    transition: border-color 0.2s;
    
    &:hover {
      border: 2px solid #0f0;
      cursor: pointer;      
    }
  }
</style>
  