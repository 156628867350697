import Vue from 'vue';
import App from './App.vue';
import store from './store';
import { useYakuzaStore } from '@/store/yakuza';
import router from './router';
import Vuetify from 'vuetify';
import AudioVisual from 'vue-audio-visual';
import { createPinia, PiniaVuePlugin } from 'pinia';
import 'vuetify/dist/vuetify.min.css';
import './assets/css/stylesheet.css';
import i18n from './i18n';

import config from "./config";
import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore";


const firebaseApp = initializeApp({
  apiKey: "AIzaSyCV3o6ouiG4CX4FYGNUXqfNc1XC1RM4tQQ",
  authDomain: "qr-code-stand-fij.firebaseapp.com",
  projectId: "qr-code-stand-fij",
  storageBucket: "qr-code-stand-fij.appspot.com",
  messagingSenderId: "456587246695",
  appId: "1:456587246695:web:f92b23fe52d67ff8c3a8c2"
})

const firestore = getFirestore(firebaseApp);

Vue.prototype.$firestore = firestore;

const pad = function(num, size) {
  var s = "000000000" + num;
  return s.substr(s.length-size);
}



Vue.config.productionTip = false
Vue.use(Vuetify);
Vue.use(AudioVisual);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();
const yakuzaStore = useYakuzaStore(pinia);

Vue.prototype.$yakuzaStore = yakuzaStore;


Vue.filter('numbers', (value) => {
  let number = value + 1
  if (number < 10) {
    return "0" + number + "."
  } 
  return number + "."
})

Vue.filter('minutes', (value) => {
  if (!value || typeof value !== "number") return "00:00"
  let min = parseInt(value / 60),
      sec = parseInt(value % 60)
  min = min < 10 ? "0" + min : min
  sec = sec < 10 ? "0" + sec : sec
  value = min + ":" + sec
  return value
})

Vue.filter('firstname', (value) => {
  const capitalizeFirstLetter = ([ first, ...rest ], locale = i18n.locale) => first.toLocaleUpperCase(locale) + rest.join('');
  return capitalizeFirstLetter(value);
})

Vue.filter('stripExtension', (value) => {
  return value.split(".")[0];
})

Vue.filter('chatTimestamp', (isoDate) => {
  const year = isoDate.getUTCFullYear();
  const month = isoDate.getUTCMonth() + 1;
  const day = isoDate.getUTCDate();
  const hours = isoDate.getUTCHours();
  const minutes = isoDate.getUTCMinutes();
  const strDay = pad(day, 2);
  const strMonth = pad(month, 2);
  const strYear = year.toString().slice(-2);
  const strHours = pad(hours, 2);
  const strMinutes = pad(minutes, 2);

  return `${strDay}.${strMonth}.${strYear}.${strHours}:${strMinutes}`;
})


Vue.filter('longDate', (isoDate) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const dateObj = (typeof isoDate === "string") ? new Date(Date.parse(isoDate)) : isoDate;

  return dateObj.toLocaleDateString(i18n.locale, options);
});

Vue.filter('shortDate', (isoDate) => {
  const dateObj = (typeof isoDate === "string") ? new Date(Date.parse(isoDate)) : isoDate;

  return dateObj.toLocaleDateString(i18n.locale);
});

Vue.filter("shortTime", (isoDate) => {
  const dateObj = (typeof isoDate === "string") ? new Date(Date.parse(isoDate)) : isoDate;

  return dateObj.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
});

Vue.filter("initial", (value) => {
  if (value === undefined || value.length === 0)
    return "";
    
  return value.charAt(0); 
});

new Vue({
  router,
  vuetify: new Vuetify(),
  i18n,
  store,
  render: h => h(App),
  beforeCreate() {
    if (!this.$store.getters.initialized)
      this.$store.commit('initializeStore', config);

    if(!this.$yakuzaStore.initialized) {
      this.$yakuzaStore.initialize(config);
    }
	},
  pinia,
}).$mount('#app');
