<template>
  <div class="grow">
    <v-container class="phone-container">
      <v-overlay absolute class="text-center" v-if="showSMS">
        <div class="sms-frame" @click="showSMS = false">
          <div class="box-bubble box-bubble-left vibrate-1" v-html="$t('yakuza.call.sms_text')">
          </div>
        </div>
      </v-overlay>      
      <SvgPhoneCall
        class="phone-ui"
        @onDial="onDial"
        ref="dialui"
      ></SvgPhoneCall>
      <Sound :src="`${casePublicAudio}/call/ringing.mp3`" ref="ringingSound" />
      <Sound :src="`${casePublicAudio}/call/engaged.mp3`" ref="engagedSound" />
      <Sound :src="`${casePublicAudio}/call/ding.mp3`" ref="dingSound" />      
    </v-container>
  </div>
</template>

<script>
  import SvgPhoneCall from'@/components/yakuza/SvgPhoneCall';
  import Sound from'@/components/yakuza/Sound'; 
  import currentGameMixin from "@/mixins/currentGameMixin";
  
  export default {
    name: "Call",
    components: { SvgPhoneCall, Sound },
    mixins: [currentGameMixin],

    data: () => ({ 
      showSMS: false,
    }),

    methods: {
      onDial: function (dialedNumber) {
        if(dialedNumber === this.$yakuzaStore.call.phoneNumber) {
          this.$refs.dialui.ring();
          this.$refs.ringingSound.play();

          setTimeout(() => {
            this.$refs.ringingSound.stop();
            this.$refs.dialui.hangUp();

            // Show SMS
            setTimeout(() => {
              this.$refs.dingSound.play();
              this.showSMS = true;
            }, 1000);
          }, 3000);
        } else {
          this.$refs.dialui.engage();
          this.$refs.engagedSound.play();

          setTimeout(() => {
              this.$refs.engagedSound.stop();
              this.$refs.dialui.hangUp();
          }, 4000);
        }
      },
    },

    computed: { },
    created() { }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/css/yakuza.scss';

  .phone-container {
    width: 100%;
    max-width: 100%;
    background-color: #00000000;
    position: relative;
  }
  
  .phone-ui {
    max-width: 300px;
    margin-left:auto;
    margin-right: auto;
  }

  ::v-deep .v-overlay__scrim {
    opacity: 0.9 !important;
  }

  .sms-frame {
    @include size(280px, 280px);

    .box-bubble {
      width: 280px;
      margin: 50px auto;
      border-radius: 15px;
      background: #00bfb6;
      color: #fff;
      padding: 20px;
      text-align: center;
      font-weight: 900;
      font-family: "Arimo";
      position: relative;
    }

    .box-bubble-left:before {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 15px solid transparent;
      border-right: 15px solid #00bfb6;
      border-top: 15px solid #00bfb6;
      border-bottom: 15px solid transparent;
      left: -16px;
      top: 0px;
    }
  }
</style>
  