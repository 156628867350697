<template>
  <div class="grow d-flex">
    <TutorialOverlay v-if="!$store.getters.maestroTutorialDone" extraClass="maestro" @onClose="closeTutorial()"></TutorialOverlay>
    <v-app-bar
      class="d-sm-none d-md-none d-lg-none d-flex justify-end agency-bar"
      dense
      color="#00000000"
      app
      flat
    >
      <v-app-bar-nav-icon @click="gotoHome" v-if="!isEventPage">
        <img src="@/assets/images/home.svg" class="home-icon" />
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-container
      class="grow d-flex flex-column flex-nowrap mt-12 mt-md-10 pa-0"
      fluid
    >
      <v-row class="shrink" no-gutters v-if="!overlay">
        <v-col cols="12">
          <agency-header
            :gameID="caseID"
            class="agency-header d-none d-sm-flex"
            :locales="caseAvailableLocales"
            :currentLocale="currentLocale"
            showLocaleSwitcher
            v-if="!isEventPage"
          ></agency-header>
        </v-col>
      </v-row>
      <v-row class="shrink" no-gutters v-if="isEventPage && !$vuetify.breakpoint.xs" >
        <v-col cols="12" class="agency-logo-div">      
          <img
              src="/common/assets/images/55investigations_logo.svg"
              class="agency-logo"
              />
          <img
              src="/common/assets/images/55investigations_title.svg"
              class="agency-title"
          />
        </v-col>
      </v-row>
      <v-row class="shrink" no-gutters v-if="!overlay">
        <v-col cols="12">
          <case-bar
            :caseID="caseID"
            :details="details"
            class="case-bar"
          ></case-bar>
        </v-col>
      </v-row>
      <v-row
        class="shrink ma-0"
        v-if="!$vuetify.breakpoint.xs && $route.name !== 'Resolve'"
        justify="center"
      >
        <v-col cols="12" md="6">
          <game-bar v-if="!isEventPage" :menus="sections"></game-bar>
        </v-col>
      </v-row>
      <v-row class="grow ma-0" v-if="!overlay" justify="center">
        <v-col cols="12" md="6">
          <router-view :z-index="2" class="fill-height" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AgencyHeader from "../../components/AgencyHeader.vue";
import CaseBar from "../../components/CaseBar.vue";
import GameBar from "../../components/GameBar.vue";
import Cases from "@/enums/Cases";
import currentGameMixin from "../../mixins/currentGameMixin";
import TutorialOverlay from "@/components/TutorialOverlay";

export default {
  name: "App",

  components: {
    AgencyHeader,
    CaseBar,
    GameBar,
    TutorialOverlay,
  },
  mixins: [currentGameMixin],
  data: () => ({
    overlay: false,
    zIndex: 1000,
    group: null,
    absolute: false,
  }),
  methods: {
    toggleMenu() {
      this.overlay = !this.overlay;
    },
    showMenu() {
      this.overlay = true;
    },
    hideMenu() {
      this.overlay = false;
    },
    navigateTo(path) {
      this.$router.push({ path: path });
    },
    gotoHome: function () {
      this.$router.push({ name: `${this.caseID}-home` });
    },
    closeTutorial: function() {
      this.$store.dispatch('maestroTutorialDone');
    },      
  },
  computed: {
    isEventPage: function() {
      return this.$route.name.includes('event')
    },
    sections: function () {
      return this.$store.getters.sections(this.caseID).map((s) => {
        const sec = s;

        sec.title = this.$t(`${this.caseID}.data.sections.${sec.id}.title`);

        if (sec.subsections) {
          sec.subsections = sec.subsections.map((ss) => {
            const subsec = ss;

            subsec.title = this.$t(
              `${this.caseID}.data.sections.${subsec.id}.title`
            );
            return subsec;
          });
        }

        return sec;
      });
    },
    details: function () {
      const details = {};

      this.$store.getters.details(this.caseID).forEach((d) => {
        details[this.$t(`${this.caseID}.data.details.${d}.label`)] = this.$t(
          `${this.caseID}.data.details.${d}.value`
        );
      });

      return details;
    },
  },
  created() {
    this.setCurrentGame(Cases.MAESTRO);
  },
};
</script>
<style scoped>
.agency-header {
  height: 120px;
  margin-bottom: 50px;
}

.agency-logo {
  margin-top: 25px;
  position: absolute;
  height: 47px;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.agency-logo-div {
  height: 100px;
}

.agency-title {
  margin-top: 25px;
  position: absolute;
  height: 11px;
  top: 60px;
  left: 50%;
  transform: translate(-50%, 0%);
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.agency-bar {
  background-image: url(/common/assets/images/55investigations_logo.svg);
  background-repeat: none;
  background-size: 59.3px 33.61px;
  background-position-x: 16.35px;
  background-position-y: center;
}

.home-icon {
  margin-right: 20px !important;
}

.agency-logo {
  width: 59.3px;
  height: 33.61px;
}

.case-bar {
  background: #00000080;
  box-shadow: 0px 8px 9px 0px #00000054;
}

.main-container {
  display: table;
  width: 60%;
  margin: auto;
}

.main-container-mobile {
  width: 100%;
}

.gamebar-row {
  display: table-row;
  height: 37px;
}

.gamebar-cell {
  display: table-cell;
}

.router-row {
  display: table-row;
}

.router-cell {
  padding-top: 35px;
  display: table-cell;
}

.game-bar {
  margin-top: 20px;
  /* max-width: 1000px; */
}

.view {
  position: absolute;
  left: 0px;
  width: 100%;
}

.view-desktop {
  top: 480px;
}

.view-mobile {
  top: 444px;
}

* {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.setting-button {
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.06em;
  margin-top: 50px;
  color: #ffffff;
  text-transform: uppercase;
}

.settings-button:hover {
  color: #ffc122;
}
</style>
