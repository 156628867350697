<template>
  <v-container>
    <v-overlay absolute  :class="`text-center`" :value="showRegisterPopup && locked" z-index="3">
      <div class="dialog-frame">
        <p class="d-title">Bienvenue Agent Capricorne</p>
        <p class="d-text">Entrez votre adresse mail puis indiquez votre réponse.</p>
        <p>
          <input
                    type="email"
                    v-model="email"
                    @keypress.enter="hideRegisterPopup()"
                    :class="{ 'email-error': !isEmailValid }"
                />
        </p>
        <p>
          <span :class="{ 'skip-btn': isEmailValid }" @click="hideRegisterPopup()">
            Envoyer
          </span>
        </p>
      </div>
    </v-overlay>
    <v-overlay absolute  :class="`text-center`" :value="showSuccessPopup" z-index="3">
      <div class="dialog-frame">
        <p class="d-title">Bravo Agent Capricorne</p>
        <p class="d-text">Vous venez de valider avec succès notre test de recrutement.<br/>Poursuivez votre enquête en cliquant sur ce lien : </p>
        <p>
          <a target="_blank" href="https://dossierscriminels.com/collections/dossiers/products/dossiers-criminels-le-maestro-assassine">Le Maestro assassiné</a>
        </p>
        <p>
          <span class="skip-btn" @click="showSuccessPopup = false">
            Fermer
          </span>
        </p>        
      </div>
    </v-overlay>     
    <v-row class="d-flex justify-center">
      <v-col xs="12">
        <svg-safe
          :locked="locked"
          :error="error"
          :unlockMessage1="$t(`${this.caseID}.safe.messages.unlock1`)"
          :unlockMessage2="$t(`${this.caseID}.safe.messages.unlock2`)"
          :errorMessage1="$t(`${this.caseID}.safe.messages.error1`)"
          :errorMessage2="$t(`${this.caseID}.safe.messages.error2`)"
          @codeValidated="onCodeValidated"
          @input="onInput"
        ></svg-safe>
      </v-col>
    </v-row>
    <audio src="@/assets/audio/safe_open.mp3" ref="openSound" />
  </v-container>
</template>

<script>
import SvgSafe from "@/components/maestro/SvgSafe.vue";
import currentGameMixin from "../../mixins/currentGameMixin";
import safeMessages from "../../messages/maestro/safe.messages";
import { collection, addDoc, getDocs } from "firebase/firestore"; 

export default {
  components: { SvgSafe },
  name: "Event",
  data() {
    return {
      showRegisterPopup: true,
      showSuccessPopup: false,
      email: "",
      docsJson: "",
      regexMail: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    }
  },
  i18n: {
    messages: safeMessages
  },
  mixins: [currentGameMixin],
  computed: {
    locked: function () {
      return this.$store.getters.maestroSafeLocked;
    },
    error: function () {
      return this.$store.getters.maestroSafeError;
    },
    isEmailValid: function() {
      return this.regexMail.test(this.email)
    },
  },
  methods: {
    docs: async function() {
      getDocs(collection(this.$firestore, 'mailing'))
      .then((querySnap) => {
        // add each doc to 'countries' array
        querySnap.forEach((doc) => {
          let d = doc.data()
          let s = new Date(d.date.seconds * 1000)

          this.docsJson += d.source + ";" + d.email + ";" + s.toLocaleString() + "\r\n"
        })
      })
    },
    hideRegisterPopup: function() {
      if(!this.isEmailValid)
        return;

      addDoc(collection(this.$firestore, "mailing"), {
        email: this.email,
        source: "Maestro",
        date: new Date()
      })

      this.showRegisterPopup = false
    },    
    onInput() {
      if (this.error) this.$store.dispatch("maestroResetSafe");
    },
    onCodeValidated(code) {
      this.$store.dispatch("maestroTryUnlockSafe", code);
    },
    playOpenSound() {
      this.$refs.openSound.play();
    },
  },
  watch: {
    locked: function (newValue) {
      if (!newValue) {
        this.playOpenSound();
        this.showSuccessPopup = true;
      }
    },
  },
  mounted() {
    this.showSuccessPopup = !this.$store.getters.maestroSafeLocked;
    //this.docs();
  }
};
</script>

<style lang="scss" scoped>

.dialog-frame {
  border-radius: 0px;
  background: rgba(0, 0, 0, 0.8);
  height: 250px;
  width: 350px;
  padding: 0;
  border-radius: 0px !important;
  transition: all 0.25s;

  &::v-deep button {
    margin-top: 0px;

    &.v-btn.v-btn--icon {
      color: #ffc122;
    }
  }

  input {
    border-radius: 3px;
    padding: 5px;
    width: 250px;
  }

  .skip-btn {
    color: #fff;

    &:hover {
      color: #ffc122;      
      cursor: pointer;
    }
  }

  .email-error {
    border: 1px solid #999;
    color: #999;
  }

  .d-title {
    font-family: "Bebas Neue";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.06em;
    text-align: center;
    text-transform: uppercase;
    color: #ffc122;
    margin-top: 20px;
  }  

  .d-text {
    text-align: center;
    color: #fff;
    padding: 20px;
    font-family: "Source Sans 3";
    font-size: 14px;
    margin: 0;
    text-transform: none;
  }
}
</style>
