<template>
  <div class="section-frame px-6">
    <div class="horizon-container fill-height d-flex flex-wrap flex-column">
      <div class="horizon-header">
        <div class="text-left">
          <img
            src="@/assets/images/ghosts/horizon/horizon_logo.svg"
            class="horizon-logo"
          />
        </div>
      </div>
      <div class="horizon-toolbar">
        <div class="text-right">
          <img
            src="@/assets/images/ghosts/horizon/horizon_cart.svg"
            class="horizon-cart"
          />
          <span>{{ $t("ghosts.horizon.labels.cart") }}</span>
        </div>
      </div>
      <div class="horizon-login grow" v-if="isLocked">
        <v-alert type="error" v-if="loginError && !recovery.show">{{
          $t("ghosts.horizon.login.messages.error")
        }}</v-alert>
        <div class="horizon-login-dialog">
          <template v-if="recovery.show">
            <h1>
              {{ $t("ghosts.horizon.recovery.title") }}
            </h1>
            <div class="horizon-security-questions">
              <form @submit.prevent="tryRecover">
                <div
                  v-for="(question, index) in securityQuestions"
                  :key="question"
                >
                  <h2>
                    {{
                      $t(`ghosts.data.horizon.recovery.questions.${question}`)
                    }}
                  </h2>
                  <div>
                    <input
                      type="text"
                      v-model="recovery.answers[index]"
                      :class="{ 'wrong-answer': isWrongAnswer(question) }"
                      @keypress="resetRecoveryError(question)"
                    />
                  </div>
                </div>
                <button type="submit">
                  {{ $t("ghosts.horizon.login.buttons.continue") }}
                </button>
              </form>
            </div>
          </template>
          <template v-else>
            <h1>
              {{ $t("ghosts.horizon.login.title") }}
            </h1>
            <h2>
              {{ $t("ghosts.horizon.login.labels.email") }}
            </h2>
            <div>
              <input type="text" value="contact@gothictales.co.uk" readonly />
            </div>
            <h2>
              {{ $t("ghosts.horizon.login.labels.password") }}
            </h2>
            <div>
              <input type="password" />
            </div>
            <div>
              <button @click="tryLogin">
                {{ $t("ghosts.horizon.login.buttons.continue") }}
              </button>
            </div>
            <div>
              <span @click="recovery.show = true" class="hyperlink">{{
                $t("ghosts.horizon.login.links.lostPassword")
              }}</span>
            </div>
          </template>
        </div>
      </div>
      <div class="horizon-orders grow" v-else>
        <label>{{ $t("ghosts.horizon.labels.orders") }}&nbsp;</label>
        <select v-model="selectedYear" class="horizon-year-selector">
          <option v-for="year in orderYears" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
        <div
          v-for="order in filteredOrders"
          :key="order.id"
          class="horizon-order-card"
        >
          <v-row align="center">
            <v-col
              ><v-img
                :src="getOrderImageSrc(order)"
                class="horizon-order-thumbnail"
              ></v-img
            ></v-col>
            <v-col>
              <div class="horizon-order-title">
                {{ $t(`ghosts.data.horizon.orders.${order.id}.name`) }}
              </div>
              <div class="horizon-order-subtitle">
                {{ order.vendor }}
              </div>
              <div class="horizon-order-date-label">
                {{ $t("ghosts.horizon.labels.orderPlacedOn") }}
              </div>
              <div class="horizon-order-date">
                {{ getLocalizedOrderDateString(order) }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import currentGameMixin from "../../mixins/currentGameMixin";
import axios from "axios";

export default {
  name: "Horizon",
  data() {
    return {
      sectionId: "horizon",
      orders: [],
      selectedYear: null,
      loginError: false,
      recovery: {
        show: false,
        answers: [],
      },
    };
  },
  mixins: [currentGameMixin],
  computed: {
    isLocked: function () {
      return this.$store.getters.ghostsHorizonLocked;
    },
    ordersSrc: function () {
      return `${this.casePublicData}/orders.json`;
    },
    orderYears: function () {
      const years = [];

      this.orders.forEach((o) => {
        const year = o.orderDate.getFullYear();

        if (!years.includes(year)) years.push(year);
      });

      return years.sort();
    },
    filteredOrders: function () {
      let tmpOrders = [];

      if (this.selectedYear) {
        tmpOrders = this.orders.filter((o) => {
          return o.orderDate.getFullYear() === this.selectedYear;
        });
      } else {
        tmpOrders = this.orders;
      }

      return tmpOrders.sort((order1, order2) => {
        if (order2.orderDate > order1.orderDate) return 1;

        if (order2.orderDate == order1.orderDate) return 0;

        return -1;
      });
    },
    securityQuestions: function () {
      return this.$store.getters.ghostsHorizonQuestions;
    },
    recoveryAnswers: function () {
      return this.recovery.answers;
    },
    recoveryErrors: function () {
      return this.$store.getters.ghostsHorizonErrors;
    },
  },
  methods: {
    getOrderImageSrc: function (order) {
      if (order.resource)
        return `${this.casePublicImages}/${this.sectionId}/${order.resource}.jpg`;

      return `${this.casePublicImages}/${this.sectionId}/${order.id}.jpg`;
    },
    getLocalizedOrderDateString(order) {
      const options = { year: "numeric", month: "long", day: "numeric" };

      return order.orderDate.toLocaleDateString(this.$i18n.locale, options);
    },
    tryRecover: function () {
      this.$store.dispatch("ghostsTryRecoverHorizon", this.recovery.answers);
    },
    isWrongAnswer: function (question) {
      return this.recoveryErrors.indexOf(question) !== -1;
    },
    resetRecoveryError: function (question) {
      if (this.isWrongAnswer(question))
        this.$store.dispatch("ghostsResetHorizonError", question);
    },
    resetAllRecoveryErrors: function () {
        this.$store.dispatch("ghostsResetHorizonErrors");
    },
    tryLogin: function () {
      this.loginError = true;
    },
  },
  mounted: async function () {
    const { data } = await axios.get(this.ordersSrc);

    this.orders = data.map((o) => {
      const order = {
        id: o.id,
        vendor: o.vendor,
        orderDate: new Date(Date.parse(o.orderDate)),
      };

      if (o.resource)
        order.resource = o.resource;

      return order;
    });

    this.recovery.answers = new Array(this.securityQuestions.length).fill("");
    this.resetAllRecoveryErrors();
  },
};
</script>
<style scoped>
.horizon-container {
  background: #ffffff;
  padding: 0;
}
.horizon-header {
  background: #252e3a;
  padding: 17px 20px 14px;
}
.horizon-logo {
  width: 131px;
}
.horizon-cart {
  height: 29px;
  vertical-align: middle;
  margin-left: 32px;
  margin-right: 7px;
}
.horizon-toolbar {
  background: #2b405b;
  padding: 17px 20px 14px;
  font-family: Arimo;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-align: right;
}
.horizon-login {
  background: #252e3a;
}
.horizon-login-dialog {
  background: #ffffff;
  border: 1px solid #b6b6b6;
  border-radius: 5px;
  margin: 24px auto;
  width: 293px;
  padding: 23px 20px 16px;
}
.horizon-login-dialog h1 {
  font-family: Arimo;
  font-size: 22px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 14px;
}
.horizon-login-dialog h2 {
  font-family: Arimo;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 7px;
}
.horizon-login-dialog input[type="text"],
.horizon-login-dialog input[type="password"] {
  border: 1px solid #b6b6b6;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 11px;
}
.horizon-login-dialog button {
  font-family: Arimo;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(180deg, #f9da6c 0%, #df9931 100%);
  border: 1px solib #000000;
  border-radius: 5px;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 6px;
}
.horizon-login-dialog .hyperlink {
  color: #0066c0;
  cursor: pointer;
}
.horizon-login-dialog .hyperlink:hover {
  text-decoration: underline;
}
.horizon-login-dialog form {
  width: 100%;
}
.horizon-security-questions {
  display: table;
  width: 100%;
}
.horizon.security-questions > div {
  display: table-row;
}
.horizon.security-questions > div > div {
  display: table-cell;
}
.horizon-orders {
  padding: 24px 74px;
}
.horizon-security-questions input[type="text"].wrong-answer {
  background: red;
}

.horizon-orders > label {
  font-family: Arimo;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.horizon-year-selector {
  font-family: Arimo;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  box-shadow: 0px 4px 7px 0px #0000001a;
  background: #e5e5e5;
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  width: 111px;
  padding: 6px 15px 7px;
  appearance: listbox !important;
  -moz-appearance: listbox !important;
  -webkit-appearance: listbox !important;
}
.horizon-order-card {
  border: 1px solid #b6b6b6;
  border-radius: 5px;
  max-width: 353px;
  margin: 10px;
  padding: 6px 12px 5px;
}

.horizon-order-thumbnail {
  max-width: 124px;
}

.horizon-order-title,
.horizon-order-subtitle {
  font-family: Arimo;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #217193;
}

.horizon-order-date-label {
  font-family: Arimo;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #7f7f7f;
}

.horizon-order-date {
  font-family: Arimo;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
</style>